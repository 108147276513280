import { PATHS } from '@src/constants/site';
import {
  CtaFragment,
  Maybe,
  MediaAssetFragment,
  MediaVideoFragment,
  NavElFragment,
  NavSubElFragment
} from '@src/graphql/gql-types';
import {
  CONTENTFUL_PAGE_CONTENT_TYPES,
  CONTENTFUL_PAGE_CONTENT_TYPES_ARRAY,
  LINKTYPES
} from '@src/constants/contentful';
import { isDefined } from './graphql';
import {
  ContentfulPageHyperlinkFragments,
  ContentfulPageTypes,
  HyperLink
} from '@src/types/contentful';
import { cloudinaryClient } from '@src/services/cloudinary';
import { NextRouter } from 'next/router';
/**
 * Takes a GraphQL result from the [page___Paths.query.ts] files. eg: *`pageCommunityStandardPaths.query.ts`* and finds the page URL
 * - Applies business logic as to what the URL path should be based on the first **__typename** value (CONTENTFUL_PAGE_CONTENT_TYPES)
 * @param obj a struct containing the keys **slug**, **__typename** and **parentPage**
 * @param trailingSlash add trailing slash to path
 * @returns Page URL string path
 */
export const getPageUrlPath = (
  obj: ContentfulPageHyperlinkFragments,
  trailingSlash = true
): string => {
  const arraySegments = flattenPagePaths(obj);

  const arrayPath: string[] = getPaddedURLPathArray(arraySegments);

  // Turn all arrayPaths into a string
  const returnPath = arrayPathToString(arrayPath);
  // This will also remove double ups of //
  return addTrailingSlash(returnPath, trailingSlash);
};

/**
 * Loops through an array of objects and returns the `slug` from each object.
 * - This is NOT the SAME as the URL path. It does NOT apply any business logic to what the URLs are or apply any name-space fillers
 * - This just returns the direct parents to each content-type
 * - __To get the page URL Path, use *getPageUrlPath()*__

 * @param arrayOfPages __normally passed the result from *flattenPagePaths()*__
 * @returns an array of slugs eg:['page-a','page-b','page-c']
 */
export const getSlugsFromArray = (arrayOfPages: any[]): string[] => {
  return arrayOfPages.map((item) => {
    return item?.slug;
  });
};

/**
 * Takes a GraphQL result from the [page___Paths.query.ts] files. eg: *`pageCommunityStandardPaths.query.ts`*
 * - __This flattens the nested page objects from the GraphQL query result into a more manageable 1 dimensional array of objects.__
 *
 * __Details:__ looks for keys that are of the allowed nested/referenced content types
 * (content types that are deemed to be valid pages) and steps into each to create an array of parent pages.
 * - Using [array].unshift() to place array in correct order.
 * @param obj a struct containing the keys **slug**, **__typename** and **parentPage**
 * @returns an array of page segment objects.
 */
export const flattenPagePaths = (obj: any): any[] => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).reduce((acc, k) => {
    // Loop through each key, check that the value of the key is an object, not null, and has value object has slug and __typename keys
    if (
      typeof obj[k] === 'object' &&
      obj[k] !== null &&
      !!obj[k]?.slug &&
      !!obj[k]?.__typename &&
      k === 'parentPage'
    ) {
      // Now check that we are a valid page content type with __typename
      if (isValidPageContentType(obj[k]?.__typename)) {
        // Then prepend slug to front of array, so that url slug order is correct.
        acc.unshift(...flattenPagePaths(obj[k]));
      }
    } else {
      if (k === '__typename' && isValidPageContentType(obj[k])) {
        let appendObj = obj;

        if (obj[k] == 'PageSharedNews') {
          // then we have to do some data massaging and lift the 'sourcePage' date (National News) up a level
          // But replace the "__typename" PageSharedNews. This allows us to target this
          // segment different from the original National News type.
          const sharedNewsItem = { ...obj?.sourcePage, __typename: obj[k] };
          appendObj = sharedNewsItem;
        }
        const segment = { ...appendObj };
        acc.unshift(segment);
      }
    }
    return acc;
  }, [] as any);
};

/**
 * Returns the full page URL (BASE URL added to slug)
 * @param urlPath
 * @returns string
 */
export const getPageUrlWithBaseURL = (
  urlPath: string,
  trailingSlash = true
): string => {
  let sUrl = urlPath;
  if (sUrl?.startsWith('http')) {
    return sUrl;
  } else {
    if (sUrl?.startsWith('/')) {
      sUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${urlPath}`;
    } else {
      sUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${urlPath}`;
    }
  }
  return addTrailingSlash(sUrl, trailingSlash);
};

/**
 * Returns the URL without the base URL part
 * @param url
 * @returns string
 */
export const getPageUrlWithoutBaseURL = (
  url = '',
  trailingSlash = true
): string => {
  return url.replace(
    (process.env.NEXT_PUBLIC_BASE_URL as string) + (trailingSlash ? '/' : ''),
    '/'
  );
};

/**
 * Check to see if URL is a local URL, if no URL passed then it's local.
 * @param url  string
 * @returns  true | false
 */
export const isLocalUrl = (url: string): boolean => {
  if (!url) {
    return true;
  }
  return (
    url?.startsWith('/') ||
    url?.startsWith('#') ||
    url?.toLowerCase()?.includes(process.env.NEXT_PUBLIC_BASE_URL || '')
  );
};

/**
 * Get a specific Segment based on page content-types
 * @param obj a GraphQL result from the [page___Paths.query.ts] files. eg: *`pageCommunityStandardPaths.query.ts`*
 * @param __typename The type of content-type to find
 * @param mode return the First or All segments that match.
 * @returns an array of matching segments
 */
export const getSegmentsFromContentType = (
  obj: ContentfulPageHyperlinkFragments,
  __typename: ContentfulPageTypes,
  mode: 'all' | 'first'
): ContentfulPageHyperlinkFragments[] => {
  // Get the parents into a flat array of objects
  const arrayPath = flattenPagePaths(obj);
  if (mode === 'first') {
    // return the first item that matches
    const firstItemFound = arrayPath.find(
      (item) => item?.__typename === __typename
    );
    return firstItemFound ? [firstItemFound] : [];
  }
  return arrayPath.filter((item) => item?.__typename === __typename); // return any matching segments based on __typename
};

/**
 * Takes a navigation element and returns the page URL.
 * Internal contentful page links take precedence over customUrls
 * @param navElement
 * @param trailingSlash  default:true
 * @returns
 */
export const getUrlFromNavigationElement = (
  navElement: NavElFragment,
  trailingSlash = true
): string => {
  let urlPath = '/';
  const linkType = navElement?.linkType as string;

  // Do we have an internal link?
  if (navElement?.internalPageLink) {
    // Get path for internal link
    urlPath = getPageUrlPath(navElement?.internalPageLink, trailingSlash);

    if (navElement?.openLinkInNewWindow) {
      urlPath = getPageUrlWithBaseURL(urlPath);
    }
  } else {
    // Else we must have a custom link if we aren't linking to an internal page
    switch (linkType) {
      case LINKTYPES.EMAIL:
        urlPath = `email:${navElement?.customUrl}`;
        break;
      case LINKTYPES.TEL:
        urlPath = `tel:${navElement?.customUrl}`;
        break;

      default:
        urlPath = `${navElement?.customUrl}`;
        if (!urlPath.startsWith('http')) {
          urlPath = addTrailingSlash(urlPath, trailingSlash);
        }
        break;
    }
  }

  return urlPath;
};

/**
 * Get the title, urlPath details for a set of Navigation Elements
 * @param navElements
 * @param trailingSlash default:true
 * @returns
 */
export const getLinkData = (
  navElements?: Maybe<NavElFragment & NavSubElFragment>[],
  trailingSlash = true
): HyperLink[] | undefined => {
  const linkData = navElements?.filter(isDefined).map((elem) => {
    const urlPath = getUrlFromNavigationElement(elem, trailingSlash);

    // get subNavItems
    const subNav = elem?.subNavItems?.items?.filter(isDefined).map((item) => {
      return {
        sys: { id: item?.sys.id },
        title: item?.title,
        urlPath: getUrlFromNavigationElement(item, trailingSlash),
        openNewWindow: item.openLinkInNewWindow
      };
    });

    return {
      sys: { id: elem?.sys.id },
      title: elem.title,
      urlPath,
      openNewWindow: elem.openLinkInNewWindow,
      // conditionally add property
      ...(subNav?.length && { subNav: subNav })
    };
  });

  return linkData;
};

export const getCTA = (cta: CtaFragment) => {
  const { openInNewWindow, internalLink, urlLink, assetLink } = cta;

  return {
    href: getLink(internalLink, urlLink, assetLink),
    openNewWindow:
      !!openInNewWindow || getOpenNewWindow(internalLink, urlLink, assetLink)
  };
};

/**
 *
 * @param internalLink
 * @param urlLink
 * @param assetLink
 * @returns href
 */

export const getLink = (
  internalLink?: ContentfulPageHyperlinkFragments | null,
  urlLink?: string | null,
  assetLink?: MediaAssetFragment | MediaVideoFragment | null | undefined
) => {
  let href = '#'; // default to # if no link available

  if (internalLink) {
    href = getPageUrlPath(internalLink);
  } else if (urlLink) {
    href = urlLink;
  } else if (assetLink && assetLink.__typename === 'MediaAsset') {
    const assetPublicId = assetLink.cloudinarySource[0]?.public_id;
    const assetUrl = cloudinaryClient.image(assetPublicId)?.toURL();
    if (assetUrl) {
      href = assetUrl;
    }
  }
  return href;
};

/**
 *
 * @param internalLink
 * @param assetLink
 * @returns openNewWindow
 */
export const getOpenNewWindow = (
  internalLink?: ContentfulPageHyperlinkFragments | null,
  urlLink?: string | null,
  assetLink?: MediaAssetFragment | MediaVideoFragment | null | undefined
) => {
  // if internalLink is defined, prioritise it over url and asset
  if (internalLink) {
    return false;
  }
  return !!(urlLink || assetLink);
};

/**
 * Takes the result from __`flattenPagePaths()`__ and returns the correctly
 * padded (name-spaced) array for a path to a page.
 * - THIS IS THE BUSINESS LOGIC FOR A PAGE URL
 *  - Applies business logic as to what the URL path should be based on the passed **__typename** value (CONTENTFUL_PAGE_CONTENT_TYPES)
 *
 * @param arrayOfFlattenPagePaths
 * @returns
 */
export const getPaddedURLPathArray = (
  arrayOfFlattenPagePaths: any[]
): string[] => {
  let arrayPath: string[] = ['/']; // acts as basePath if we fall into the default case
  const flatArrayOfPaths = [...arrayOfFlattenPagePaths];
  const lastItemInArray = flatArrayOfPaths.slice(-1);
  const typename = lastItemInArray?.[0]?.__typename || '';

  switch (typename) {
    case CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD:
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      break;
    case CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_NEWS:
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add news prefix
      arrayPath.unshift(PATHS.NATIONAL_NEWS);
      break;

    case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY:
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;
    case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_STANDARD:
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;

    case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_NEWS:
      /**
       * We have to insert slug paths of 'news-and-events/news' after the community (CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY)
       *  - Since all community news page will have a community first
       *    we can just splice directly after the 1st position. :)
       */
      flatArrayOfPaths.splice(
        1,
        0,
        { slug: PATHS.COMMUNITY_NEWS_AND_EVENTS },
        { slug: PATHS.COMMUNITY_NEWS }
      );
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;

    case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_EVENT:
      /**
       * We have to insert slug paths of 'news-and-events/event' after the community (CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY)
       *  - Since all community event page will have a community first
       *    we can just splice directly after the 1st position. :)
       */
      flatArrayOfPaths.splice(
        1,
        0,
        { slug: PATHS.COMMUNITY_NEWS_AND_EVENTS },
        { slug: PATHS.COMMUNITY_EVENT }
      );
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;

    case CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_PROPERTY_DETAIL:
      /**
       * We have to insert slug paths of 'for-sale' after the community (CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY)
       *  - Since all community property detail pages will have a community first
       *    we can just splice directly after the 1st position. :)
       */
      flatArrayOfPaths.splice(1, 0, { slug: PATHS.COMMUNITY_FOR_SALE });
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;
    case CONTENTFUL_PAGE_CONTENT_TYPES.SHARED_NEWS:
      /**
       * We have to insert slug paths of 'news-and-events/news/shared' after the community (CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY)
       *  - Since all shared community news page will have a community first
       *    we can just splice directly after the 1st position. :)
       */
      flatArrayOfPaths.splice(
        1,
        0,
        { slug: PATHS.COMMUNITY_NEWS_AND_EVENTS },
        { slug: PATHS.COMMUNITY_NEWS },
        { slug: PATHS.COMMUNITY_SHARED }
      );
      arrayPath = getSlugsFromArray(flatArrayOfPaths);
      // Add community prefix
      arrayPath.unshift(PATHS.COMMUNITY);
      break;
    default:
      // Default case will just use the initialized `arrayPath` of ['/']
      break;
  }
  return arrayPath;
};

/**
 * Add trailing slash if requested, should only be used on internal links as it doesn't do enough testing for full external links
 *
 * - (Doesn't check for # or any query string params, just adds slash to the end of the string)
 * @param urlPath
 * @param trailingSlash
 * @returns
 */
export const addTrailingSlash = (
  urlPath: string,
  trailingSlash: boolean
): string => {
  const s: string = trailingSlash ? `${urlPath}/` : urlPath;
  return removeDoubleSlash(s);
};

/**
 * Turns an array of strings (slugs), into a URL path "/"
 * - __['page-a','page-b','page-c']__ --> __/page-a/page-b/page-c/__
 * @param arrayPath
 * @returns
 */
export const arrayPathToString = (arrayPath: string[]): string => {
  if (arrayPath.length && arrayPath[0] !== '/') {
    arrayPath.unshift('/');
  }
  return arrayPath.join('/');
};

/**
 * Checks if a given string is in the curent browser window's URL
 * @param str
 * @returns boolean
 */
export const isInBrowserURL = (str: string) => {
  if (typeof window !== 'undefined') {
    return window.location?.href?.includes(str);
  }
};

/**
 * Gets the last segment of URL without any query params
 * @returns string
 */
export const getLastSegmentFromURL = (router: NextRouter) => {
  const { asPath } = router;
  /**
   * url needs a base URL to append asPath to in order for the segments to work so we use https://www.levande.com.au as a temporary base URL.
   */
  const url = new URL(asPath, 'https://www.levande.com.au');
  const segments = url.pathname.split('/').filter(Boolean);

  return segments.pop() || null;
};

/**
 * @param itemUrl
 * @param routerPath
 * @returns true if current top level route is active
 */
export const getActiveTopLevelRoute = (
  itemUrl: Maybe<string>,
  routerPath: Maybe<string>
) => {
  const pathValue =
    itemUrl
      ?.split('/')
      .filter((e) => e)
      .pop()
      ?.replace(/\//g, '') || '';

  return routerPath?.includes(pathValue);
};

/* ====================== Private functions =====================

=================================================================*/

/**
 * Removes any double slashes from the URL string
 *
 * https:// and http:// are unaffected
 * @param urlPath
 * @returns a string with no double slashes in it
 */
const removeDoubleSlash = (urlPath: string): string => {
  let s = '';
  const splitAt =
    (index: number) =>
    (x: string): Array<string> => [x.slice(0, index), x.slice(index)];
  if (urlPath.startsWith('https://') || urlPath.startsWith('http://')) {
    const items = splitAt(7)(urlPath);
    items[1] = items[1].replace(/\/{2,}/g, '/');
    s = items.join('');
  } else {
    s = urlPath.replace(/\/{2,}/g, '/');
  }

  return s;
};

/**
 * Checks that the content type is a page content type for the site
 * @param __typename : The content type name from graphQL
 */
const isValidPageContentType = (__typename: string): boolean => {
  return CONTENTFUL_PAGE_CONTENT_TYPES_ARRAY.includes(__typename);
};

export const shallowObjectToQueryString = <T extends Record<string, any>>(
  obj: T
) => {
  const pairs: string[] = [];
  Object.entries(obj).forEach(([key, val]) => {
    pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
  });
  return pairs.join('&');
};
